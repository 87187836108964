import React from 'react'

function services() {
    return (
        <>
            <section id="servicesPage">
                <div className="container">
                    <div className="col-sm-11 col-xl-9 m-md-auto pt-5 pb-3">
                        <h1 className='servicesPage-heading display-3 fw-bold'><span style={{ color: "#615af7" }}>Transforming Business</span> Through Quality Call Center Services
                        </h1>
                        <p className='servicesPage-para pt-3'>H&P Global Networks excels in providing comprehensive, top-notch BPO services designed to meet your business needs. With these inbound and outbound call center services, H&P Global Networks is committed to providing you a seamless, efficient, and reliable customer service experience.</p>
                    </div>
                    <div className="row py-5 pt-3 mb-5 px-4 servicesPage-Box rounded-4">
                        <div className="col-md-10 text-center m-auto">
                            <h2 className='fw-bold fs-1 servicesPage-subheading'>Inbound Call Center Services</h2>
                            <p className='servicesPage-subpara'>At H&P Global Networks, we offer a wide range of inbound call center services to optimize your customer engagement strategies. Our focus is on delivering efficient, reliable, and tailored solutions that meet the specific needs of your business. By outsourcing these essential functions to us, you can save time and resources while maintaining a high level of customer satisfaction.</p>
                        </div>
                        <div className="col-12py -4">
                            <div className="row pt-4">
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Lead <br />Qualification</h4>
                                            <p className='reviews-box-para'>Our experts identify and assess the potential of incoming leads, ensuring your sales team interacts only with highly qualified prospects. This streamlines your sales funnel and increases conversion rates.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Direct Call Center Service</h4>
                                            <p className='reviews-box-para'>We manage direct interactions with your customers, providing them with immediate solutions to their queries or issues. Our timely and professional service helps to improve customer loyalty.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Live Phone Answering Service</h4>
                                            <p className='reviews-box-para'>Our trained professionals offer live phone answering services, ensuring that every call is answered promptly and courteously. This minimizes wait times and enhances the overall customer experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Medical Answering Service</h4>
                                            <p className='reviews-box-para'>We provide specialized answering services for medical practices, offering appointment setting, emergency call routing, and secure message delivery. Your patients receive timely and confidential care.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Chat Support Services</h4>
                                            <p className='reviews-box-para'>Our real-time chat support is designed to offer instant assistance to customers navigating your website. This boosts customer satisfaction and can help in increasing sales conversions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Outsource Tech Support</h4>
                                            <p className='reviews-box-para'>By outsourcing your technical support to us, you ensure expert troubleshooting and problem resolution. This allows you to focus on core business functions while we handle tech-related customer concerns.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Outsource Email Support</h4>
                                            <p className='reviews-box-para'>Exceptional doesn't even begin to describe the level of service we've received. H&P Global Networks has integrated so well with our existing operations that they feel like an essential part of our long-term business strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Help Desk</h4>
                                            <p className='reviews-box-para'>Our help desk services provide comprehensive support, from troubleshooting technical issues to offering informational resources. With a focus on prompt and effective resolution, we aim to enhance customer satisfaction and loyalty.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5 pt-5 my-5 px-4 servicesPage-Box rounded-4">
                        <div className="col-md-10 text-center m-auto">
                            <h2 className='fw-bold fs-1 servicesPage-subheading'>Outbound Call Center Services</h2>
                            <p className='servicesPage-subpara'>Elevate your business outreach with H&P Global Networks' comprehensive outbound call center services. Our expert team engages proactively with your customers to drive results.</p>
                        </div>
                        <div className="col-12py -4">
                            <div className="row pt-4">
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Telemarketing</h4>
                                            <p className='reviews-box-para'>Boost sales and expand your customer base through personalized marketing calls that deliver results. Our skilled agents create persuasive conversations to maximize your ROI.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Lead Generation</h4>
                                            <p className='reviews-box-para'>Identify potential customers, nurture prospects, and set appointments to accelerate your sales process. Our data-driven approach ensures high-quality leads for your business.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Live Call Transfer</h4>
                                            <p className='reviews-box-para'>Seamlessly connect customers to the right agents for instant assistance, enhancing customer satisfaction. Enjoy a smooth flow of inquiries and resolutions in real-time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3 mb-4">
                                    <div className="servicesPage-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-4 fw-bold'>Call Back Services</h4>
                                            <p className='reviews-box-para'>Provide prompt callbacks to address customer inquiries and concerns, showing your commitment to exceptional service. Enhance customer loyalty by resolving issues promptly and effectively.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default services