import React from 'react'

function Contact() {
    return (
        <section id="contactPage">
            <div className="container py-5">
                <div className="col-sm-11 col-xl-9 m-md-auto">
                    <h1 className='contactPage-heading display-2 fw-bold'><span style={{ color: "#615af7" }}>Powering </span>Businesses to Thrive</h1>
                    <p className='contactPage-para'>We offer comprehensive call center services for American Businesses both inbound and outbound. We are:</p>
                </div>
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="contactPage-Box px-sm-5 py-5 px-3 rounded-5 my-5">
                            <form>
                                <div class="mb-3">
                                    <label for="User_Name" class="form-label">Full Name</label>
                                    <input type="text" class="form-control" name='User_Name' id="User_Name" placeholder="Full Name" />
                                </div>
                                <div class="mb-3">
                                    <label for="User_Email" class="form-label">Email</label>
                                    <input type="email" class="form-control" name='User_Email' id="User_Email" placeholder="name@example.com" />
                                </div>
                                <div class="mb-3">
                                    <label for="User_Message" class="form-label">Enter Message</label>
                                    <textarea class="form-control" id="User_Message" name='User_Message' rows="5" placeholder='Type Here...' ></textarea>
                                </div>
                                <div class="mb-3 d-flex justify-content-center">
                                    <input type="submit" class="contactPage-submit-btn btn ms-auto" id="submit" value="Submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact