import React from 'react'

function about() {
    return (
        <>
            <section id="aboutPage" className='py-5'>
                <div className="container">
                    <div className="col-sm-11 col-xl-9 m-md-auto">
                        <h1 className='aboutPage-heading display-2 fw-bold'><span style={{ color: "#615af7" }}>Powering </span>Businesses to Thrive</h1>
                        <p className='aboutPage-para'>We offer comprehensive call center services for American Businesses both inbound and outbound. We are:</p>
                    </div>
                    <div className="row py-5">
                        <div className="col-sm-11 col-xl-9 m-auto">
                            <div className="row px-4 px-sm-0">
                                <div className="col-lg-6 order-2 order-lg-1">
                                    <h3 className='aboutPage-subHeading'>Leading BPO Services Providers:</h3>
                                    <p className='aboutPage-subPara'>In a business environment that’s increasingly driven by customer expectations, H&P Global Networks has emerged as a trusted partner for businesses seeking to elevate their customer experience. Established with a vision to revolutionise the call center industry, we provide comprehensive inbound and outbound call center services as well as BPO solutions tailored to your business needs.</p>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 mb-3 mb-lg-0">
                                    <img src="assets/img/about-img-1.webp" alt="H&P Global Networks" className='aboutPage-img rounded-4 d-flex m-auto' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className=" col-sm-11 col-xl-9 m-auto">
                            <div className="row px-4 px-sm-0">
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <img src="assets/img/about-img-2.webp" alt="H&P Global Networks" className='aboutPage-img rounded-4 d-flex m-auto' />
                                </div>
                                <div className="col-lg-6">
                                    <h3 className='aboutPage-subHeading'>Result Driven Practises to Expand Profit</h3>
                                    <p className='aboutPage-subPara'>Our heritage is rooted in creating value for our clients through innovative call center solutions, leveraging cutting-edge technologies, and above all, putting the customer at the heart of everything we do. With a team of skilled professionals, we provide an array of services that span from handling customer queries to offering technical support and conducting customer surveys.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section id="aboutPage-Box">
                <div className="container py-5">
                    <div className="row  py-3">
                        <div className="col-12">
                            <h4 className='aboutPage-Box-heading h-primary text-center'>Our Values</h4>
                        </div>
                        <div className="col-9 m-auto fw-bold">
                            <h3 className='aboutPage-Box-main-heading text-center fs-3 text-center'>We are committed to delivering exceptional service by upholding values that reflect integrity, innovation, and customer-centricity.</h3>
                        </div>
                        <div className="col-12 py-3 aboutPage-Box-main-box rounded-5 py-5 my-4">
                            <div className="row py-3">
                                <div className="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div className="aboutPage-Box-box position-relative rounded-4 p-4 pt-5 m-auto">
                                        <div className="aboutPage-Box-img-box position-absolute d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/Customer-Centric Approach.webp" alt="H and P Global Networks" className='aboutPage-Box-box-img' />
                                        </div>
                                        <h3 className='pt-3 aboutPage-Box-box-heading'>Customer-Centric Approach</h3>
                                        <p className='aboutPage-Box-box-para'>Our focus is on understanding and meeting customer needs, ensuring satisfaction at every touchpoint</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div className="aboutPage-Box-box position-relative rounded-4 p-4 pt-5 m-auto">
                                        <div className="aboutPage-Box-img-box position-absolute d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/Technological Innovation.webp" alt="H and P Global Networks" className='aboutPage-Box-box-img' />
                                        </div>
                                        <h3 className='pt-3 aboutPage-Box-box-heading'>Technological <br /> Innovation </h3>
                                        <p className='aboutPage-Box-box-para'>We leverage advanced technologies to offer streamlined and efficient call center services that set industry standards.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div className="aboutPage-Box-box position-relative rounded-4 p-4 pt-5 m-auto">
                                        <div className="aboutPage-Box-img-box position-absolute d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/Ethical Integrity.webp" alt="H and P Global Networks" className='aboutPage-Box-box-img' />
                                        </div>
                                        <h3 className='pt-3 aboutPage-Box-box-heading'>Ethical <br />Integrity</h3>
                                        <p className='aboutPage-Box-box-para'>Trust and transparency govern our operations, making us a reliable partner in your business journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default about