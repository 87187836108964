import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/about';
// import advertiser from './components/pages/advertiser';
import advertiser from './components/pages/services';
import Header from './components/header';
import Footer from './components/footer';
import service from './components/pages/service';
import Contact from './components/pages/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/about' Component={About} />
          <Route path='/services' Component={advertiser} />
          <Route path='/industries' Component={service} />
          <Route path='/contact' Component={Contact} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
