import React from 'react'
import { Link } from 'react-router-dom'

function footer() {
    return (
        <>
            <footer id='foot' className='text-white py-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-3 mb-md-0 px-4">
                            <h3 className='footer-heading'>H&P Global Networks</h3>
                            <p className='footer-para'>As a trusted partner for businesses nationwide, H&P Global Networks offers a comprehensive suite of call center services and BPO solutions. Our commitment to quality, innovation, and customer satisfaction sets us apart in the industry.</p>
                        </div>
                        <div className="col-md-4 d-none d-md-flex justify-content-center align-items-center">
                            <div className="footer-logo-box d-flex justify-content-center align-items-center">
                                <img src="assets/img/H&P-logo.png" alt="H and P Global Networks" />
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0 d-flex flex-column align-items-center justify-content-center">
                            <h3>Our Pages</h3>
                            <ul className='list-unstyled d-flex flex-column align-items-center'>
                                <li className='pb-1'><Link to="/" className='text-decoration-none position-relative text-white footer-nav-link'>Home</Link></li>
                                <li className='pb-1'><Link to="/services" className='text-decoration-none position-relative text-white footer-nav-link'>Services</Link></li>
                                <li className='pb-1'><Link to="/advertisers" className='text-decoration-none position-relative text-white footer-nav-link'>Advertisers</Link></li>
                                <li className='pb-1'><Link to="/about" className='text-decoration-none position-relative text-white footer-nav-link'>About us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div id='developed'>
                <p className='m-0 text-center py-3 text-white'>&copy; 2023-2024 handpglobalnetworks.com. All Rights Reserved | Developed by <a href='https://www.linkedin.com/company/creactiveinc/' target='_blank' rel="noreferrer" className='developed-link text-decoration-none'>Creactive</a></p>
            </div>
        </>
    )
}

export default footer