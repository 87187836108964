import React from 'react'
import { Link } from 'react-router-dom';

function Home() {
    return (
        <>
            <section id="heading">
                <div className="container py-5">
                    <div className="row py-5">
                        <div className=" order-2 order-lg-1 col-lg-7 col-xl-6">
                            <h1 className='heading-main-heading display-3 fw-bold'>Drive Your Business Success with H & P Gobal Network</h1>
                            <p className='w-75 pb-4'>Unlock business growth and customer satisfaction with our top-notch BPO and call center services.</p>
                            <Link to="/about" className="btn heading-btn mb03">About Us</Link>
                        </div>
                        <div className="order-1 order-lg-2 col-lg-5 col-xl-6 d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                            <img src="assets/img/heading.svg" alt="H and P Global Networks" className='w-100' />
                        </div>
                    </div>
                </div>
            </section>
            <section id="clients">
                <div className="container py-5">
                    <div className="col-sm-5 col-lg-3">
                        <p className='client-fold-description'>Worked with 100+ clients around the world including:</p>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="client-carousel py-3">
                                <div className="client-img d-flex justify-content-between">
                                    <img src="assets/svg/client.svg" alt="h and p Global Network" className='me-4' />
                                    <img src="assets/img/client-01.webp" alt="h and p Global Network" className='me-4' />
                                    <img src="assets/img/client-02.webp" alt="h and p Global Network" className='me-4' />
                                    <img src="assets/img/client-03.webp" alt="h and p Global Network" className='me-4' />
                                    <img src="assets/img/client-04.webp" alt="h and p Global Network" className='me-4' />
                                    <img src="assets/img/client-05.webp" alt="h and p Global Network" className='me-4' />
                                    <img src="assets/img/client-06.webp" alt="h and p Global Network" className='me-4' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="whoWeAre">
                <div className="container py-3 py-md-5">
                    <div className="row">
                        <div className="col-12">
                            <h3 className='who-small-heading text-center h-primary'>Who we are?</h3>
                        </div>
                        <div className="col-sm-11 col-lg-10 m-auto">
                            <h1 className="who-main-heading display-5 fw-bold text-center">We are your business growth partners. Our customized solutions are designed to align with your business objectives.</h1>
                        </div>
                        <div className="col-12">
                            <div className="row py-4">
                                <div className="col-lg-6">
                                    <img src="assets/img/who we are.webp" alt="H and P Global Networks" className='w-100' />
                                </div>
                                <div className="col-lg-6 d-flex align-items-center">
                                    <p className='fs-5 text-center text-sm-start'>
                                        H&P Global Networks is a leading provider of versatile call center solutions and BPO services. We specialize in enhancing customer engagement and boosting revenue for our clients. Established with a vision to revolutionise the call center industry, we provide comprehensive inbound and outbound call center services as well as BPO solutions tailored to your business needs. <Link to="/about" className='text-decoration-none'>Read more...</Link></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="about">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="about-heading h-primary text-center">Our Values</h3>
                        </div>
                        <div className="col-md-9 m-auto">
                            <h1 className='about-main-heading fs-2 fw-bold text-center'>We are committed to delivering exceptional service by upholding values that reflect integrity, innovation, and customer-centricity.</h1>
                        </div>
                        <div className="col-12 py-4">
                            <div className="row">
                                <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                    <div className="about-box rounded-3 px-4 py-2 m-auto">
                                        <div className="col-12 d-flex justify-content-center py-4">
                                            <img src="assets/img/target-audience.webp" alt="H and P Global Network" />
                                        </div>
                                        <div className="col-12 text-center">
                                            <h4 className='about-box-heading fs-3 fw-bold'>Customer-Centric Approach</h4>
                                            <p className='about-box-para'>Our focus is on understanding and meeting customer needs, ensuring satisfaction at every touchpoint</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                    <div className="about-box rounded-3 px-4 py-2 m-auto">
                                        <div className="col-12 d-flex justify-content-center py-4">
                                            <img src="assets/img/project-management.webp" alt="H and P Global Network" />
                                        </div>
                                        <div className="col-12 text-center">
                                            <h4 className='about-box-heading fs-3 fw-bold'>Technological Innovation</h4>
                                            <p className='about-box-para'>We leverage advanced technologies to offer streamlined and efficient call center services that set industry standards.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                    <div className="about-box rounded-3 px-4 py-2 m-auto">
                                        <div className="col-12 d-flex justify-content-center py-4">
                                            <img src="assets/img/ethics.webp" alt="H and P Global Network" />
                                        </div>
                                        <div className="col-12 text-center">
                                            <h4 className='about-box-heading fs-3 fw-bold'>Ethical <br />Integrity </h4>
                                            <p className='about-box-para'>Trust and transparency govern our operations, making us a reliable partner in your business journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <section id="vertical">
                <div className="container py-5">
                    <div className="row  py-3">
                        <div className="col-12">
                            <h4 className='vertical-heading h-primary text-center'>Our Services </h4>
                        </div>
                        <div className="col-9 m-auto fw-bold">
                            <h3 className='vertical-main-heading text-center fs-3 text-center'>Elevate your business operations with our range of specialized services.</h3>
                        </div>
                        <div className="col-12 py-3 vertical-main-box rounded-5 py-5 my-4">
                            <div className="row py-3">
                                <div className="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div className="vertical-box position-relative rounded-4 p-4 pt-5 m-auto">
                                        <div className="vertical-img-box position-absolute d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/Inbound Call Center Services.webp" alt="H and P Global Networks" className='vertical-box-img' />
                                        </div>
                                        <h3 className='pt-3 vertical-box-heading'>Inbound Call Center Services</h3>
                                        <p className='vertical-box-para'>Never miss a call again. Our inbound call center services are designed to manage incoming calls effectively, from customer service inquiries to technical support.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div className="vertical-box position-relative rounded-4 p-4 pt-5 m-auto">
                                        <div className="vertical-img-box position-absolute d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/outbound Call Center Services.webp" alt="H and P Global Networks" className='vertical-box-img' />
                                        </div>
                                        <h3 className='pt-3 vertical-box-heading'>Outbound Call Center Services</h3>
                                        <p className='vertical-box-para'>Increase revenue and customer engagement through our outbound call center solutions that include telemarketing, customer surveys, and appointment setting.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div className="vertical-box position-relative rounded-4 p-4 pt-5 m-auto">
                                        <div className="vertical-img-box position-absolute d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/Contact Center.webp" alt="H and P Global Networks" className='vertical-box-img' />
                                        </div>
                                        <h3 className='pt-3 vertical-box-heading'>Contact <br /> Center</h3>
                                        <p className='vertical-box-para'>Our integrated contact center solutions go beyond voice calls, including email, chat, and social media interactions, providing a unified customer experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="reviews">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="reviews-heading h-primary text-center">Our Reviews</h3>
                        </div>
                        <div className="col-md-9 m-auto">
                            <h1 className='reviews-main-heading fs-2 fw-bold text-center'>Read what our clients have to say about the excellence we deliver.</h1>
                        </div>
                        <div className="col-12 py-4">
                            <div className="row">
                                <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                    <div className="reviews-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-3 fw-bold'>Andrew Mathisan</h4>
                                            <p className='reviews-box-para'>The customer service provided by H&P Global Networks is truly incredible. Their team is responsive, professional, and attentive, leading to a marked improvement in our revenue and customer satisfaction metrics.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                    <div className="reviews-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-3 fw-bold'>David</h4>
                                            <p className='reviews-box-para'>Exceptional doesn't even begin to describe the level of service we've received. H&P Global Networks has integrated so well with our existing operations that they feel like an essential part of our long-term business strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                    <div className="reviews-box rounded-4 px-4 py-2 m-auto">
                                        <div className="col-12 text-center">
                                            <h4 className='reviews-box-heading fs-3 fw-bold'>Clark Gilbert</h4>
                                            <p className='reviews-box-para'>As a trusted partner for businesses nationwide, H&P Global Networks offers a comprehensive suite of call center services and BPO solutions. Our commitment to quality, innovation, and customer satisfaction sets us apart in the industry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default Home