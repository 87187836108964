import React from 'react'

function service() {
    return (
        <section id="servicePage">
            <div className="container py-5">
                <div className="col-sm-11 col-xl-10 m-md-auto px-3 px-sm-0">
                    <h1 className='servicePage-heading display-3 fw-bold mb-3'><span style={{ color: "#615af7" }}>Empowering Businesses</span> Across Diverse Industries</h1>
                    <p className='servicePage-para'>Elevate Your Business and unlock growth potential with H and P Global Networks: Your Call Center Partner for Success!</p>
                    <p className="servicePage-subpara">At H and P Global Networks, we understand that each industry has its unique requirements. That's why our call center services are tailored to meet the specific needs of our clients. With a dedicated and skilled team, advanced technology, and a commitment to excellence, we help businesses in these diverse industries thrive. With our unwavering focus on customer satisfaction, we aim to enhance your business operations, streamline processes, and drive growth. Partner with us to elevate your business and provide exceptional customer experiences. Our expertise extends to the following sectors:</p>
                </div>
                <div className="col-sm-11 m-auto py-4">
                    <div className="servicePage-main-box rounded-4 py-5">
                        <h4 className='text-center servicePage-box-main-heading h-primary pb-4'>Industries We Serve</h4>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="servicePage-sub-box m-auto d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-4">
                                    <div className="servicePage-img-box d-flex justify-content-center align-items-center rounded-circle mb-4">
                                        <img src="assets/svg/Artboard 1.svg" alt="H and P Global Networks" className='w-50' />
                                    </div>
                                    <h4 className='servicePage-box-heading text-center'>Insurance</h4>
                                    <p className='servicePage-box-para'>In the insurance industry, we streamline customer support and claims processing, ensuring quick resolutions and enhanced client satisfaction. Our inbound call center services specialize in addressing policy inquiries and providing assistance when it matters most.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="servicePage-sub-box m-auto d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-4">
                                    <div className="servicePage-img-box d-flex justify-content-center align-items-center rounded-circle mb-4">
                                        <img src="assets/svg/Artboard 1 copy 4.svg" alt="H and P Global Networks" className='w-50' />
                                    </div>
                                    <h4 className='servicePage-box-heading text-center'>Healthcare</h4>
                                    <p className='servicePage-box-para'>With 60 percent of people finding their home services help online, building a highly visible web presence is critical to the success of your business. Our team has specialists with years of marketing experience in your particular line of work.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="servicePage-sub-box m-auto d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-4">
                                    <div className="servicePage-img-box d-flex justify-content-center align-items-center rounded-circle mb-4">
                                        <img src="assets/svg/Artboard 1 copy 3.svg" alt="H and P Global Networks" className='w-50' />
                                    </div>
                                    <h4 className='servicePage-box-heading text-center'>Real Estate</h4>
                                    <p className='servicePage-box-para'>For the real estate industry, our outbound call center services excel in lead generation and nurturing, helping agents and brokers connect with potential buyers and sellers. We also provide round-the-clock assistance to address inquiries about property listings.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="servicePage-sub-box m-auto d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-4">
                                    <div className="servicePage-img-box d-flex justify-content-center align-items-center rounded-circle mb-4">
                                        <img src="assets/svg/Artboard 1 copy 5.svg" alt="H and P Global Networks" className='w-50' />
                                    </div>
                                    <h4 className='servicePage-box-heading text-center'>Home Services</h4>
                                    <p className='servicePage-box-para'>In the home services sector, we enhance customer experience by efficiently managing appointment bookings, service requests, and after-sales support. Our call center solutions ensure your customers receive timely assistance.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="servicePage-sub-box m-auto d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-4">
                                    <div className="servicePage-img-box d-flex justify-content-center align-items-center rounded-circle mb-4">
                                        <img src="assets/svg/Artboard 1 copy 2.svg" alt="H and P Global Networks" className='w-50' />
                                    </div>
                                    <h4 className='servicePage-box-heading text-center text-center'>Finance and Banking</h4>
                                    <p className='servicePage-box-para'>In the world of finance and banking, accuracy and reliability are paramount. We offer inbound and outbound services for account inquiries, transaction support, and credit card assistance, ensuring a secure and efficient banking experience.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="servicePage-sub-box m-auto d-flex flex-column justify-content-center align-items-center px-4 py-4 rounded-4">
                                    <div className="servicePage-img-box d-flex justify-content-center align-items-center rounded-circle mb-4">
                                        <img src="assets/svg/Artboard 1 copy.svg" alt="H and P Global Networks" className='w-50' />
                                    </div>
                                    <h4 className='servicePage-box-heading text-center'>Retail and Consumer Goods</h4>
                                    <p className='servicePage-box-para'>For retail and consumer goods companies, we provide comprehensive customer support solutions. Our team handles inquiries about products, orders, returns, and more, enhancing your customers' shopping experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default service